<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="3">
        <v-text-field
          outlined
          dense
          label="Key"
          v-model="record.key"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          outlined
          dense
          label="Title"
          v-model="record.title"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          outlined
          dense
          type="number"
          label="Index"
          v-model="record.indexno"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="3">
        <v-checkbox
          outlined
          dense
          v-model="record.mandatory"
          label="Mandatory"
        ></v-checkbox>
      </v-col>

      <v-col cols="6">
        <v-autocomplete
          outlined
          dense
          v-model="record.type_id"
          :items="field_types"
          item-text="Name"
          item-value="id"
          label="Field Type"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-checkbox
          outlined
          dense
          v-model="record.status"
          label="Active"
        ></v-checkbox>
      </v-col>
    </v-row>
    <template v-if="record.type_id === 4">
      <v-btn color="success" @click="addField" class="my-5"
        >Add DropDown Value</v-btn
      >

      <v-row dense>
        <v-col
          cols="6"
          v-for="(field, index) in fields"
          :key="index"
          :class="{ 'd-flex': true, 'align-items-center': true }"
        >
          <v-text-field
            outlined
            dense
            v-model="field.Value"
            label="value"
          ></v-text-field>
          <v-btn class="mx-2" color="warning" @click="removeField(index)"
            >Remove
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-row dense>
      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    record: {
      drop_down_values: [],
    },
    fields: [{ value: "" }],
    loader: false,
    field_types: [],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      console.log(val, "initial");
      this.record = { ...val };
    },
    addField() {
      this.fields.push({ value: "" });
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },
    setDate(date) {
      this.record.date = date;
    },
    sendData() {
      this.loader = true;
      this.record.drop_down_values = this.fields;
      this.$emit("data", this.record);
    },
    getFieldTypes() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/field_types`)
        .then((res) => {
          self.field_types = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
          self.loading = false;
        });
    },
  },
  created() {
    this.getFieldTypes();
  },
};
</script>